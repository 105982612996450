<template>
  <v-navigation-drawer
    :value="showDrawer"
    @input="setDrawer"
    right
    stateless
    hide-overlay
    app
    class="map__drawer">
    <v-btn
      text
      :elevation="0"
      class="map-drawer__btn ma-0 pa-0"
      @click="closeDrawer"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="content-wrapper px-4">
      <h3 class="body-1--gi mb-8">
        {{ selectedHub.Title }}
      </h3>

      <p class="body-4--gi mb-2 font-weight-bold">
        <img 
          v-for="(icon, i) in iconList" 
          :key="i" 
          class="mr-3"
          :src="icon === 'pangolin' ? 
          require('@/assets/allcomm-icons/' + `${icon}` + '.png') :
          require('@/assets/allcomm-icons/' + `${icon}` + '.svg')
          " 
          alt="icons" 
          width="36px" />
      </p>

       <p class="body-4--gi mb-1 font-weight-bold">Commodity type(s): {{ selectedHub.AllComm }}</p>
       <p class="body-4--gi mb-1 font-weight-bold">Location: {{ selectedHub.Location }}</p>
      <p class="body-4--gi mb-6 font-weight-bold">Country: {{ selectedHub.Country }}</p>


      <p class="body-4--gi">{{ selectedHub.Commodities }}</p>
      <p class="body-4--gi mb-8">{{ selectedHub.Vulnerabilities }}</p>

      <p v-if="selectedHub.Link" class="body-4--gi mb-1 font-weight-bold">External Links <br> {{ selectedHub.Links }}</p>      
    </div>    
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import _kebabCase from 'lodash.kebabcase';

export default {
  name: 'MapSideDrawer',
  computed: {
    showDrawer: {
      get: function() {
        return this.$store.getters['drawerState/showDrawer'];
      },
      set: function() {
        return this.$store.dispatch('drawerState/updateDrawerState');
      }      
    },
    iconList() {
      const array = this.selectedHub.AllComm.split(', ');
      return array.map(d => _kebabCase(d).split('-')[0])
    },
    ...mapGetters('drawerState', ['selectedHub']),
    ...mapGetters('popupState', ['selectedFeature'])    
  },
  methods: {
    closeDrawer() {
      this.$store.dispatch('drawerState/updateDrawerState', false)
    },
   setDrawer(e) {
      this.$store.dispatch('drawerState/updateDrawerState', e);
    }    
  }
};
</script>

<style lang="scss" scoped> 
.map__drawer {
  overflow: unset;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.45);
  top: 75px !important;
  height: calc(100vh - 75px) !important;
  width: 300px !important;
  z-index: 100;
  background-color: #47385C !important;
  // position: relative;

  ::v-deep .v-navigation-drawer__content {
    position: relative;
    overflow-y: hidden;
  }

  .content-wrapper {
    height: 100%;
    overflow-y: auto;
    background-color: #47385C;
    color: #FFFFFF;
    top: 68px;
    padding-bottom: 96px;
    position: absolute;    
    .body-1--gi {
      color: #FFFFFF !important;      
    }      
  }
  .content-wrapper::-webkit-scrollbar {
    display: none;
  }

  .content-wrapper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }    

  .map-drawer__btn {
    position: absolute;
    top: 18px;
    z-index: 10;
    left: 16px;
    min-width: auto;
    color: #FFFFFF;
  }
}
</style>
