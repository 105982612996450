<template>
  <v-container pt-10 pb-6 px-4>
    <v-row>
      <v-col cols="12" class="content-wrapper">
        <FeatureContent />
      </v-col> 
    </v-row>
  </v-container>
</template>

<script>
import FeatureContent from '@/components/controllers/FeatureContent';

export default {
  name: 'MobileDrawerInfo',
  components: {
    FeatureContent
  }
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  overflow-y: scroll;
  max-height: 450px;
  margin-top: 12px;
}
</style>
