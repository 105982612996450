<template>
  <v-container py-0>
    <v-row>
      <v-col cols="12" class="text-left">
        <!-- Start of Panels -->
        <v-expansion-panels multiple flat>

          <!-- Cat 1: Hotspots -->
          <p class="body-3--gi layerTitle">BRI-Crime Hotspots</p>
          <v-switch 
            hide-details
            inset flat dense 
            :ripple="false"
            v-model="allCommodities"            
            class="my-0">
            <template v-slot:label>
              <span class="legend-color" style="background-color: #B0A894;"/>
              <span class="caption--gi">Hotspots</span>
            </template>          
          </v-switch>  

          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header class="mb-1">
              <span class="caption--gi pl-9">Environmental</span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-checkbox 
                v-for="(item, i) in hotspotEnv" 
                :key="'hotspotEnv' + i" 
                hide-details flat dense
                color="#E9E2DA" 
                :ripple="false"
                :value= item.value
                v-model="selectedCommodities"                
                class="mt-0 pl-2 mb-2">
                <template v-slot:label>
                  <span class="caption--gi">{{ item.label }}</span>
                </template>           
              </v-checkbox>              
            </v-expansion-panel-content>
          </v-expansion-panel> 

          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header class="mb-1">
              <span class="caption--gi pl-9">Narcotics</span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-checkbox 
                v-for="(item, i) in hotspotNarc" 
                :key="'hotspotNarc' + i" 
                hide-details flat dense
                color="#E9E2DA" 
                :ripple="false"
                :value= item.value
                v-model="selectedCommodities"                
                class="mt-0 pl-2 mb-2">
                <template v-slot:label>
                  <span class="caption--gi">{{ item.label }}</span>
                </template>           
              </v-checkbox>              
            </v-expansion-panel-content>
          </v-expansion-panel> 

          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header  expand-icon="">
              <v-checkbox 
                hide-details flat dense
                color="#E9E2DA" 
                :ripple="false"
                class="mt-0 pl-8"
                v-model="selectedCommodities"  
                value= "Human">
                <template v-slot:label>
                  <span class="caption--gi mb-0">People</span>
                </template>           
              </v-checkbox>   
            </v-expansion-panel-header>
          </v-expansion-panel>               
        </v-expansion-panels>

        <v-expansion-panels accordion flat>

          <!-- Cat 3: Routes -->
          <p class="body-3--gi layerTitle">Trafficking routes</p>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-switch 
                hide-details
                inset flat dense 
                :ripple="false"
                v-model="allRouteEnv"
                class="my-0 pl-1">
                <template v-slot:label>
                  <span class="caption--gi">Environmental</span>
                </template>          
              </v-switch>  
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-switch 
                v-for="(item, i) in routeEnv" 
                :key="'routeEnv' + i" 
                hide-details 
                :value= item.value
                :ripple="false"
                inset flat dense
                v-model="selectedRouteEnv"                
                class="mt-0 pl-5">
                <template v-slot:label>
                  <span class="legend-color" :style="`background-color: ${item.color};`"/>
                  <span class="caption--gi">{{ item.label }}</span>
                </template>           
              </v-switch>              
            </v-expansion-panel-content>
          </v-expansion-panel> 

          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-switch 
                hide-details
                inset flat dense 
                :ripple="false"
                v-model="allRouteNarc"
                class="my-0 pl-1">
                <template v-slot:label>
                  <span class="caption--gi">Narcotics</span>
                </template>          
              </v-switch>  
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-switch 
                v-for="(item, i) in routeNarc" 
                :key="'routeNarc' + i" 
                hide-details 
                :value= item.value
                :ripple="false"
                inset flat dense
                v-model="selectedRouteNarc"                
                class="mt-0 pl-5">
                <template v-slot:label>
                  <span class="legend-color" :style="`background-color: ${item.color};`"/>
                  <span class="caption--gi">{{ item.label }}</span>
                </template>           
              </v-switch>              
            </v-expansion-panel-content>
          </v-expansion-panel> 

          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="">
              <v-switch 
                hide-details
                inset flat dense 
                :ripple="false"
                v-model="routePeople"
                class="my-0 pl-1">
                <template v-slot:label>
                  <span class="legend-color" style="background-color: #458CBD;"/>
                  <span class="caption--gi">People</span>
                </template>          
              </v-switch>  
            </v-expansion-panel-header>
          </v-expansion-panel>  

          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="">
              <v-switch 
                hide-details
                inset flat dense 
                :ripple="false"
                v-model="routeGoods"
                class="my-0 pl-1">
                <template v-slot:label>
                  <span class="legend-color" style="background-color: #A33347;"/>
                  <span class="caption--gi">Counterfeit goods <br>and medicine</span>
                </template>          
              </v-switch>  
            </v-expansion-panel-header>
          </v-expansion-panel>  
          
          <!-- Cat 1: Infrastucture -->
          <p class="body-3--gi layerTitle">Infrastructure</p>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-switch 
                hide-details
                inset flat dense 
                :ripple="false"
                v-model="allBelt"
                class="my-0 pl-1" color="red">
                <template v-slot:label>
                  <span class="legend-color" style="background-color: #6D58AD;"/>
                  <span class="caption--gi">Belt and Roads</span>
                </template>          
              </v-switch>            
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-switch 
                v-for="(item, j) in belts" 
                :key="'belt-' + j" hide-details color="#ff2424" 
                :value= item.value 
                :ripple="false"
                v-model="selectedBelt"          
                inset flat dense 
                class="mt-0 pl-5">
                  <template v-slot:label>
                    <span class="caption--gi">{{ item.label }}</span>
                  </template> 
              </v-switch>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header>
              <v-switch 
                hide-details
                inset flat dense 
                :ripple="false"
                v-model="allCorridor"
                class="my-0 pl-1">
                <template v-slot:label>
                  <span class="legend-color" style="background-color: #47385C;"/>
                  <span class="caption--gi">Economic corridors</span>
                </template>          
              </v-switch>            
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-switch 
                v-for="(item, j) in corridors" 
                :key="'corridor' + j" hide-details color="#ff2424" 
                :value= item.value 
                :ripple="false"
                v-model="selectedCorridor"          
                inset flat dense 
                class="mt-0 pl-5">
                  <template v-slot:label>
                    <span class="caption--gi">{{ item.label }}</span>
                  </template> 
              </v-switch>
            </v-expansion-panel-content>
          </v-expansion-panel>          

          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header>
              <v-switch 
                hide-details
                inset flat dense
                :ripple="false"
                v-model="allProjects"
                class="my-0 pl-1">
                <template v-slot:label>
                  <span class="legend-color" style="background-color: #78708A;"/>
                  <span class="caption--gi">Infrastructure projects</span>
                </template>          
              </v-switch>            
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-switch 
                v-for="(item, i) in projects" 
                :key="'project' + i" hide-details
                :ripple="false"
                :value= item.value
                v-model="selectedProjects"
                inset flat dense
                class="mt-0 pl-5">
                <template v-slot:label>
                  <!-- <span class="legend-color" :style="`background-color: ${item.color};`"/> -->
                  <span class="caption--gi">{{ item.label }}</span>
                </template>           
              </v-switch>
            </v-expansion-panel-content>
          </v-expansion-panel>     

        <!-- END OF PANELS  -->
        </v-expansion-panels>        
      </v-col>       
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'MapControls', 
  data() {
    return {
      // Infrastructure
      projects: [
        {label:'Ports', value: 'ports', color: '#78708A'},        
        {label:'Railways', value: 'railways', color: '#605675'},
      ],
      belts: [
        {label:'Maritime Silk Road', value: 'belt-1'},
        {label:'Silk Road Economic Belt', value: 'belt-2'},
      ],
      corridors: [
        {label:'Bangladesh-China-India-Myanmar', value: 'corridor-1'},
        {label:'China-Central Asia-West Asia', value: 'corridor-2'},
        {label:'China-Indochina Peninsula', value: 'corridor-3'},
        {label:'China-Mongolia-Russia', value: 'corridor-4'},
        {label:'China-Pakistan', value: 'corridor-5'},
        {label:'New Eurasia Land Bridge', value: 'corridor-6'},
      ],

      // Hotspotsspots
      hotspotEnv: [
        {label: 'Ivory', value: 'Ivory', color: '#BDEEDC'},
        {label: 'Pangolin', value: 'Pangolin or pangolin scales', color: '#BDEEDC'},
        {label: 'Rhino horns', value: 'Rhino horn', color: '#BDEEDC'},
        {label: 'Tiger', value: 'Tiger', color: '#BDEEDC'},
        {label: 'Timber', value: 'Timber', color: '#BDEEDC'},
        {label: 'Minerals', value: 'Minerals', color: '#789C88'},
        {label: 'Gemstones', value: 'Gemstones', color: '#34B685'},
      ],
      hotspotNarc: [
        {label: 'Cannabis', value: 'Cannabis', color: '#BDEEDC'},
        {label: 'Cocaine', value: 'Cocaine', color: '#BDEEDC'},
        {label: 'Heroin', value: 'Heroin', color: '#BDEEDC'},
        {label: 'Synthetic drugs', value: 'Synthetic drugs', color: '#BDEEDC'},
      ],

      // Routes
      routeEnv: [
        {label: 'Wildlife', value: 'Wildlife-flow', color: '#002F13'},
        {label: 'Gold', value: 'Gold-flow', color: '#276642'},
        {label: 'Timber', value: 'Timber-flow', color: '#658B76'},
        {label: 'Gemstones', value: 'Gemstones-flow', color: '#89BCA0'},
        {label: 'Charcoal', value: 'Charcoal-flow', color: '#34B685'},
        {label: 'ODS', value: 'ODS-flow', color: '#5BEFB7' },
        {label: 'E-waste', value: 'Waste-flow', color: '#BDEEDC'},
      ],      
      routeNarc: [
        {label: 'Heroin', value: 'Heroin-flow', color: '#E37900'},
        {label: 'Synthetic drugs', value: 'Synthetic-flow', color: '#FFA43D'},
        {label: 'Cocaine', value: 'Cocaine-flow', color: '#DDAC74'},
        {label: 'Precursors', value: 'Precursors-flow', color: '#FFD4A9'},
      ],     
    };
  },
  computed: {
    // Belts
    allBelt: {
      get: function() {
        return this.$store.getters['layerState/allBelt'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllBelt', value);
      }  
    },
    selectedBelt: {
      get: function () {
        return this.$store.getters['layerState/belt'];
      },      
      /* We add a setter */
      set: function (value) {
        this.$store.dispatch('layerState/updateBelt', value);
      }
    },

    // Corridors
    allCorridor: {
      get: function() {
        return this.$store.getters['layerState/allCorridor'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllCorridor', value);
      }  
    },
    selectedCorridor: {
      get: function () {
        return this.$store.getters['layerState/corridor'];
      },      
      /* We add a setter */
      set: function (value) {
        this.$store.dispatch('layerState/updateCorridor', value);
      }
    },

    // Infrastructure Projects   
    allProjects: {
      get: function() {
        return this.$store.getters['layerState/allProject'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllProject', value);
      }      
    },
    selectedProjects: {
      get: function () {
        return this.$store.getters['layerState/project'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateProject', value);
      }
    },

    // Hotspotsspots
    allCommodities: {
      get: function() {
        return this.$store.getters['layerState/allCommodities'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllCommodities', value);
      }   
    },
    
    selectedCommodities: {
      get: function () {
        return this.$store.getters['layerState/commodities'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateCommodities', value);
      }
    },    
    
    // Routes -Env 
    allRouteEnv: {
      get: function() {
        return this.$store.getters['layerState/allRouteEnv'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllRouteEnv', value);
      }      
    },
    selectedRouteEnv: {
      get: function () {
        return this.$store.getters['layerState/routeEnv'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateRouteEnv', value);
      }
    },
    
    // Routes - Narc
    allRouteNarc: {
      get: function() {
        return this.$store.getters['layerState/allRouteNarc'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllRouteNarc', value);
      }      
    },
    selectedRouteNarc: {
      get: function () {
        return this.$store.getters['layerState/routeNarc'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateRouteNarc', value);
      }
    },

    // Routes - People
    routePeople: {
      get: function () {
        return this.$store.getters['layerState/routePeople'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateRoutePeople', value);
      }
    },

    // Routes - Goods
    routeGoods: {
      get: function () {
        return this.$store.getters['layerState/routeGoods'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateRouteGoods', value);
      }
    },    
  },
};
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
   padding-top: 0px !important;
   flex: 1 1 auto !important;
}

.legend-color {
  width: 13px; 
  height: 13px; 
  margin-right: 7px;
  display: inline-block; 
  border: 0.5px solid #E9E2DA; 
  vertical-align: middle; 
}

::v-deep .v-input--selection-controls__input {
  margin-top: 10px !important;  
  .v-input--switch__track {
    opacity: 1;
    border: 2px solid #e3dcd5;
    background-color: #47385c;
  }
  .v-input--switch__thumb {
    background-color: #e3dcd5;
    top: calc(50% - 9px);    
  }
}

.v-input--checkbox {
  ::v-deep .v-input--selection-controls__input {
    margin-top: 0px !important;  
  }

  ::v-deep .mdi-checkbox-blank-outline {
    color: #E9E2DA !important;
  }
}

.v-input--is-label-active {
  ::v-deep .v-input--selection-controls__input {
    .v-input--switch__track {
      background-color: #e3dcd5;
    }
    .v-input--switch__thumb {
      background-color: #47385c;
    }
  }
}

.v-expansion-panel {
  background-color: transparent !important;
  color: #E9E2DA !important;

  .v-expansion-panel-content {
    ::v-deep .v-expansion-panel-content__wrap {
      padding: 0 24px !important;
    }
  }

  button.v-expansion-panel-header {
    padding: 0 !important;
    min-height: 0 !important;
    ::v-deep .v-icon {
        color: #E9E2DA !important;
    }
  }
  button.v-expansion-panel-header--active {
    min-height: 0 !important;
  }
}

.layerTitle {
  width: 100%;
  margin-bottom: 16px;
  margin-top: 32px;
}
</style>
