<template>
<div>
  <!-- Placeholder  -->
  <h3 v-if="!selectedFeature" class="body-4--gi mb-0 font-italic">Start clicking on the map features for more information</h3>  

  <!-- Overland - Belts -->
  <div v-if="selectedFeature === 'belts'">
    <h3 class="body-4--gi mb-0 font-weight-bold">{{properties.Name}}</h3>
  </div>
  
  <!-- Overland - Corridors  -->
  <div v-if="selectedFeature === 'corridors'">
    <h3 class="body-4--gi mb-0 font-weight-bold">{{ properties.Name }}</h3>
  </div>

  <!-- Projects - Railways -->
  <div v-if="selectedFeature === 'railways'">
    <h3 class="body-4--gi font-weight-bold mb-2">
      {{ properties.Name }}
    </h3>
    <p class="body-4--gi mb-0">Current status: {{ properties.Status }}</p>
  </div>

  <!-- Projects - Ports -->
  <div v-if="selectedFeature === 'ports'">
    <h3 class="body-4--gi font-weight-bold mb-0">{{ properties.Name }}</h3>
  </div>

  <!-- Trafficking -->
  <div v-if="selectedFeature.split('-')[0] === 'trafficking'">
    <!-- for flow -->
    <div v-if="properties.source && properties.dest">
      <h3 class="body-4--gi mb-2 font-weight-bold">Trafficked item(s): {{ properties.items }}</h3>
      <p class="body-4--gi mb-0">From {{ properties.source }} to {{ properties.dest }}</p>
    </div>
    <!-- for point -->
    <div v-else>
      <h3 class="body-4--gi mb-2 font-weight-bold">Trafficked item(s): {{ properties.items }}</h3>
      <p  class="body-4--gi mb-0">{{ properties.locType}}: {{ properties.location }}</p>
    </div>    
  </div>  


  <!-- Hubs - All  -->
  <div v-if="selectedFeature === 'hubs'">
    <h3 class="body-4--gi font-weight-bold mb-2">
      {{ properties.Title }}
    </h3>
    <div class="hidden-sm-and-down">
      <p class="body-4--gi mb-2">
        Commodity type(s): {{ properties.AllComm }}
      </p>
      <p class="body-4--gi mb-0 button">
        <v-icon color="#E9E2DA" class="mr-0" style="font-size: 1.2rem;" >mdi-mouse</v-icon>
        Click the circle for more info
      </p>
    </div>        

    <!-- only for mobile  -->
    <div class="hidden-md-and-up">     
      <p class="body-4--gi mb-2 font-weight-bold">
        <img 
          v-for="(icon, i) in iconList" 
          :key="i" 
          class="mr-3 mt-2"
          :src="icon === 'pangolin' ? 
          require('@/assets/allcomm-icons/' + `${icon}` + '.png') :
          require('@/assets/allcomm-icons/' + `${icon}` + '.svg')
          " 
          alt="icons" 
          width="36px" />
      </p>

      <p class="body-4--gi mb-1 font-weight-bold">Commodity type(s): {{ properties.AllComm }}</p>       
      <p class="body-4--gi mb-1 font-weight-bold">Location: {{ properties.Location }}</p>
      <p class="body-4--gi mb-5 font-weight-bold">Country: {{ properties.Country }}</p>    
      <p class="body-4--gi">
        {{ properties.Commodities }}
      </p>
      <p class="body-4--gi">        
        {{ properties.Vulnerabilities }}
      </p>
    </div>
  </div>
 
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import _kebabCase from 'lodash.kebabcase';

export default {
  name: 'Popup',
  computed: {
    ...mapGetters('popupState', ['properties', 'selectedFeature']),  
    iconList() {
      const array = this.properties.AllComm.split(',');
      return array.map(d => _kebabCase(d).split('-')[0])
    },  
  },
};
</script>
