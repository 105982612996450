<template>
  <div class="view-interactivemap">
    <v-row style="padding: 0; margin: 0;">
      <v-col 
        cols="12" md="3" lg="2"
        class="hidden-sm-and-down" 
        style="padding: 0; margin: 0;">
          <MapControlPanel />
      </v-col>   

      <v-col 
        cols="12" md="9" lg="10"
        style="padding: 0; margin: 0;">
          <Map />        
      </v-col>
    
      <MapSideDrawer class="hidden-sm-and-down" />
    </v-row>

    <MapBottomNavMobile class="hidden-md-and-up" />

    <v-dialog
      v-model="dialog"
      max-width="475">
        <div class="dialog-text pa-4" v-if="this.$vuetify.breakpoint.smAndDown">
          <p class="body-2--gi text--light">
            This tool currently represents trafficking routes and infrastructures related to South East Asia, Central and Eastern Africa only.
          </p>
          <p class="body-2--gi mb-0 text--light">
            It is advisable to visualise infrastructure projects and BRI-crime hotspots simultaneously.
          </p>
          
          <hr class="my-3">

          <v-row align="start" justify="space-between">
            <v-col cols="2">
              <v-icon color='#E9E2DA' style="font-size: 2.7rem; display: block">mdi-gesture-tap</v-icon>
            </v-col>
            <v-col cols="10">            
              <p class="body-2--gi text--light" style="display: block">            
                Move the map around to explore the datapoints. <br>Tap anywhere to continue.
              </p>
            </v-col>
          </v-row>
        </div>

        <div  v-if="this.$vuetify.breakpoint.mdAndUp" class="dialog-text--desktop pa-6">
          <h3 class="body-3--gi font-weight-bold mb-5 text-center"><v-icon color="#E9E2DA" class="mr-2">mdi-lightbulb-outline</v-icon>Top tips</h3>              
          <p class="body-4--gi text--light">
            This tool currently represents trafficking routes and infrastructures related to South East Asia, central and East Africa only.
          </p>
          <p class="body-4--gi mb-0 text--light">
            It is advisable to visualize infrastructure projects and BRI crime hotspots simultaneously.
          </p>
          
          <hr class="my-3">

          <p class="body-4--gi mb-0 text--light">
            Hover over the lines and points to learn more. Click on the BRI crime hotspots circles to read more on the specific hubs.
          </p> 

          <v-btn class="dialog-close px-6 py-2 mt-6" elevation="0" :ripple="false" @click="dialog = false">
            <p class="body-4--gi mb-0 text--light">
              Got it
            </p>
          </v-btn>               
        </div>        
    </v-dialog>       
  </div>
</template>

<script>
import Map from '@/components/viz/Map';
import MapControlPanel from '@/components/views/map/MapControlPanel';
import MapSideDrawer from '@/components/views/map/MapSideDrawer';
import MapBottomNavMobile from '@/components/views/map/MapBottomNavMobile';

export default {
  name: 'InteractiveMap',
  components: {
    Map,
    MapControlPanel,
    MapSideDrawer,
    MapBottomNavMobile,
  },  
  data() {
    return {
      showDrawer: false,
      dialog: true,
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.view-interactivemap {
  width: 100vw;
  > * {
    padding: 0;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 100%;
  }  
}

.dialog-text {
  background-color: #6D58AD;;
  border-radius: 4px;
}

.dialog-text--desktop {
  background-color: #47385C;;
  border-radius: 4px;
}

hr {
  border: 1px solid #E9E2DA;
}

.v-btn.dialog-close {
  background-color: #6D58AD;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
}
</style>
