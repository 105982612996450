<template>
<div class="bottom-nav__wrapper">
  <!-- Content to pull up -->
  <div class="bottom-nav__content-wrapper" id="mobile-drawer-height" >
    
    <div class="bottom-nav__content" :class="{ 'drawerState': showDrawer }">
      <v-btn
        text
        :elevation="0"
        v-show="selectedButton === 'info'"
        class="bottom-nav__content-button ma-0 pa-0"
        @click="closeDrawer">
        <v-icon color="#E9E2DA" style="font-size: 18px">mdi-close</v-icon>
      </v-btn>      
      <Legend v-show="selectedButton === 'legend'" />
      <LayerSwitchMobile  
        v-show="selectedButton === 'layers'"/>
      <InfoDrawerMobile  
        v-show="selectedButton === 'info'" />        
    </div>
  </div> 
  <!-- Nav bar -->
  <v-bottom-navigation
    color="#e9e2da"
    height="55px"
    background-color="#47385c"
    :ripple="false"
    light
    grow>

    <v-btn @click="toggleDrawer('legend')" :class="{ 'btn--active': selectedButton === 'legend' && showDrawer }">
      <span class="overline--gi text--light">Legend</span>
      <v-icon color="#E9E2DA">mdi-information-outline</v-icon>
    </v-btn>

    <v-btn @click="toggleDrawer('layers')" :class="{ 'btn--active': selectedButton === 'layers' && showDrawer }">
      <span class="overline--gi text--light">Map Layers</span>
      <v-icon color="#E9E2DA">mdi-layers</v-icon>
    </v-btn>

    <v-btn @click="toggleDrawer('info')" :class="{ 'btn--active': selectedButton === 'info' && showDrawer }">
      <span class="overline--gi text--light">Information</span>
      <v-icon color="#E9E2DA">mdi-pin</v-icon>
    </v-btn>
  </v-bottom-navigation>
</div>
</template>

<script>
import Legend from '@/components/ui/Legend';
import LayerSwitchMobile from '@/components/controllers/LayerSwitchesMobile';
import InfoDrawerMobile from '@/components/ui/InfoDrawerMobile';

export default {
  name: 'MobileBottomNav',
  components: {
    Legend,
    LayerSwitchMobile,
    InfoDrawerMobile
  },
  computed: {
    showDrawer: {
      get: function() {
        return this.$store.getters['drawerState/infoDrawer'];
      },
      set: function() {
        return this.$store.dispatch('drawerState/updateInfoDrawerState');
      }      
    },
    selectedButton: {
      get: function() {
        return this.$store.getters['drawerState/selectedButton'];
      },
      set: function() {
        return this.$store.dispatch('drawerState/updateSelectedButton');
      }      
    },    
  },
  mounted() {
    window.addEventListener('load', () => {
      let vh = window.innerHeight * 0.01;
      document.getElementById('mobile-drawer-height').style.setProperty('--vh', `${vh}px`);
    });     
  },
  methods: {
    toggleDrawer(content) {
      if (content === this.selectedButton) {
        this.$store.dispatch('drawerState/updateInfoDrawerState', !this.showDrawer);
      } else {
        this.$store.dispatch('drawerState/updateInfoDrawerState', true);
        this.$store.dispatch('drawerState/updateSelectedButton', content);
      }      
    },
    closeDrawer() {
      this.$store.dispatch('drawerState/updateInfoDrawerState', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin all-transition($time) {
	transition: all $time;
	-webkit-transition: all $time;
}

.bottom-nav__wrapper {
  position: relative;
}

.v-bottom-navigation {
  z-index: 3;
}

.bottom-nav__content-wrapper{
	height: auto;
  max-height: 100%;
	z-index: 2;
  background-color: transparent;
  // overflow: scroll;
  position: absolute;
  bottom: 55px;
  width: 100%;
  pointer-events: none;
}

#mobile-drawer-height {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-height: calc(var(--vh, 1vh) * 100 - 56px - 65px);
  }    
}

.bottom-nav__content {
	-webkit-transform: translateY(100%); 
  transform: translateY(100%); 
	@include all-transition(0.5s);
  background-color: rgba(71, 56, 92, 0.9);
  position: relative;

  .bottom-nav__content-button  {
    position: absolute;
    top: 4px;
    right: -8px;
  }
}

.drawerState {
  transform: translate3d(0, 0, 0);
  animation-timing-function: 1.5s ease-in;
  pointer-events: auto;
}

.btn--active {
  background-color: #E9E2DA !important;
  .v-icon, span.overline--gi {
    color: #47385C !important;
  }
}

.row {
  ::v-deep .v-overlay {
    display: none !important;
  }
}

</style>

