<template>
  <section>
    <div id="map" class="map">
      <Popup />
    </div>
  </section>
</template>

<script>
import _kebabCase from 'lodash.kebabcase';
import * as d3 from 'd3';

import _Mapbox from '@/plugins/mapbox';
import { MapboxLayer } from "@deck.gl/mapbox";
import { ArcLayer } from '@deck.gl/layers';

import Popup from '@/components/ui/Popup';
import { mapGetters } from 'vuex';

export default {
  name: 'Map',
  components: {
    Popup,
  },
  data() {
    return {
      map: null,
      infra: {
        belts: null,
        corridors: null,
        projects: [
          'railways-stops',
          'ports',
          'railways',
          'railways-planned',
          'railways-bg'
        ],
      }, 
      routes: {
        narc: ['Cocaine-flow', 'Heroin-flow', 'Precursors-flow', 'Synthetic-flow'],
        env: ['Wildlife-flow', 'Wildlife-point', 'Gemstones-flow', 'Gold-flow', 'Timber-flow', 'Charcoal-flow', 'Waste-flow', 'ODS-flow'],
      },
      hotspots: {
        narc: ['Cocaine', 'Heroin', 'Cannabis', 'Synthetic drugs'],
        env: ['Ivory', 'Pangolin or pangolin scales', 'Rhino horn', 'Tiger', 'Timber', 'Minerals', 'Gemstones'],
      },
      hubSource: null,    
    };
  },
  computed: {
    defaultOptions() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return {
          container: 'map',
          style: 'mapbox://styles/claudiolandi89/ckmvsri4i0tyc17ntddtmspt5',
          center: [105.364, 12.554],
          zoom: 2.8,          
        };
      }
      return {
        container: 'map',
        style: 'mapbox://styles/claudiolandi89/ckmvsri4i0tyc17ntddtmspt5',
        center: [70.597, 8.486],
        zoom: 2.01,
      };
    },    
     ...mapGetters('layerState', [
       'belt', 'corridor', 'project', 
       'routeEnv','routeNarc', 'routePeople', 'routeGoods',
       'commodities',     
      ]),
     ...mapGetters('drawerState', ['selectedButton', 'showDrawer']), 
  },
  watch: {  
    // Infrastructures
    belt(newVal) {
      this.handleSwitchSelections(this.infra.belts, newVal);
    },
    corridor(newVal) {
      this.handleSwitchSelections(this.infra.corridors, newVal);
    },    
    project(newVal) {  
      this.handleSwitchSelections(this.infra.projects, newVal);
    },
    // Hotspot
    commodities(newVal) {
      this.handleHubSwitch(newVal);
    },
    // Routes
    routePeople(newVal) {  
      this.handleSwitchSelections('People-flow', newVal);
    },
    routeGoods(newVal) {  
      this.handleSwitchSelections('Others-flow', newVal);
    },    
    routeNarc(newVal) {  
      this.handleSwitchSelections(this.routes.narc, newVal);
    },
    routeEnv(newVal) {  
      this.handleSwitchSelections(this.routes.env, newVal);
    },    
  },
  mounted() {
    this.createLayerData();

    // listen to window size
    window.addEventListener('load', () => {
      let vh = window.innerHeight * 0.01;
      document.getElementById('map').style.setProperty('--vh', `${vh}px`);
    }); 

    // init Mapbox
    this.map = _Mapbox.createMap(this.defaultOptions);

    // basic map interaction setting 
    this.map.addControl(
      _Mapbox.createControl({showCompass: false}), 
      this.$vuetify.breakpoint.smAndDown ? 'top-right' : 'bottom-left');        
    this.map.touchZoomRotate.disableRotation();
    this.map.touchPitch.disable();

    this.map.on('load', () => {

      // adding map layers on load
      this.addBeltLayers();
      this.addCorridorLayers();
      this.addProjectsLayers();
      this.addTraffickingLayers();
      this.addHubLayers();      
    });
    
    // apply tooltip to mapbox layers
    this.handleTooltip();

    // on Mobile only
    this.map.on('click', (e) => {
      if (!this.map.queryRenderedFeatures(e.point).some(d => d.layer.id === 'hub-layer')) {
        this.$store.dispatch('drawerState/updateDrawerState', false)
      }
      // console.log(this.map.queryRenderedFeatures(e.point))
      // check for selectedButton and close the opened drawers
      if (this.selectedButton !== 'info') {
        this.$store.dispatch('drawerState/updateInfoDrawerState')
      } 
    });
  },
  beforeDestroy() {
    this.resetMap();
  },  
  methods: {
    resetMap() {
      this.$store.dispatch('layerState/updateRoutePeople', false);
      this.$store.dispatch('layerState/updateRouteGoods', false);
      this.$store.dispatch('layerState/updateAllRouteNarc', false);
      this.$store.dispatch('layerState/updateAllRouteEnv', false);

      this.$store.dispatch('layerState/updateAllCorridor', false);
      this.$store.dispatch('layerState/updateAllBelt', false);
      this.$store.dispatch('layerState/updateAllProject', false);

      this.$store.dispatch('layerState/updateAllCommodities', true);
    },       
    createLayerData() {
      const belts = Array.from({length: 2}, (d, i) => 'belt-' + (i + 1));
      const beltsbg = Array.from({length: 2}, (d, i) => 'belt-' + (i + 1) + '_bg');
      this.infra.belts = belts.concat(beltsbg);

      const corrs = Array.from({length: 6}, (d, i) => 'corridor-' + (i + 1));
      const corrsbg = Array.from({length: 6}, (d, i) => 'corridor-' + (i + 1) + '_bg');
      this.infra.corridors = corrs.concat(corrsbg);
    },
    // map layers for infrastructure
    addBeltLayers() {
      const belts = ['belt-1', 'belt-2'];
      // add source
      this.map.addSource('belts', {
        type: 'geojson',
        data: 'data/belts.json',
        generateId: true
      });

      // add layer line
      belts.forEach(d => {
        this.map.addLayer({
          id: d,
          type: 'line',
          source: 'belts',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
            'visibility': 'none'
          },
          paint: {
            'line-color': '#6D58AD',
            'line-opacity': 0.7,
            'line-width': [
              'interpolate', 
              ['linear'], ['zoom'], 
              1, 2, 
              2.99, 2, 
              3.5, 4,
              5, 6 
            ],
          },                
          filter: ["==", "id", d]
        });

        this.map.addLayer({
          id: d + '_bg',
          type: 'line',
          source: 'belts',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
            'visibility': 'none'
          },
          paint: {
            'line-color': 'black',
            'line-opacity': 0,
            'line-width': 10
          },                
          filter: ["==", "id", d]
        });
      });   
    },
    addCorridorLayers() {
      const corridors = ['corridor-1', 'corridor-2', 'corridor-3', 'corridor-4', 'corridor-5', 'corridor-6'];
      // add source
      this.map.addSource('corridors', {
        type: 'geojson',
        data: 'data/corridors.json',
      });

      // add layer line
      corridors.forEach(d => {
        this.map.addLayer({
          id: d,
          type: 'line',
          source: 'corridors',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
            'visibility': 'none'
          },
          paint: {
            'line-color': '#47385C',
            'line-opacity': 0.7,
            'line-width': [
              'interpolate', 
              ['linear'], ['zoom'], 
              1, 2, 
              2.99, 2, 
              3.5, 4,
              5, 6 
            ],
          },                  
          filter: ["==", "id", d]
        });

        this.map.addLayer({
          id: d + '_bg',
          type: 'line',
          source: 'corridors',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
            'visibility': 'none'
          },
          paint: {
            'line-color': '#47385C',
            'line-opacity': 0,
            'line-width': 10
          },                  
          filter: ["==", "id", d]
        });        
      });
    },    
    addProjectsLayers() {
      // add source
      const projectDataSource = ['ports', 'railways-stops', 'railways'];
      projectDataSource.forEach(data => {
        this.map.addSource(data, {
          type: 'geojson',
          data: `data/projects-${data}.json`,
          generateId: true
        });     
      });

      this.infra.projects.forEach(project => {
        switch(project) {
          case 'railways':
            this.map.addLayer({
              id: project,
              type: 'line',
              source: 'railways',
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
                'visibility': 'none',
              },
              paint: {
                'line-color':'#78708A',
                'line-opacity': 0.7,
                'line-width': 2,
                'line-dasharray': [1, 2]
              },
              filter: ["==", "Status", 'Planned']
            });
          break

          case 'railways-planned':
            this.map.addLayer({
              id: project,
              type: 'line',
              source: 'railways',
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
                'visibility': 'none',
              },
              paint: {
                'line-color':'#78708A',
                'line-opacity': 0.7,
                'line-width': 2,
              },
              filter: ["==", "Status", 'Completed']
            });     
          break

          case 'railways-bg':
            this.map.addLayer({
              id: project,
              type: 'line',
              source: 'railways',
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
                'visibility': 'none',
              },
              paint: {
                'line-color':'#78708A',
                'line-opacity': 0,
                'line-width': 10,
              },
            });
          break

          default: 
          this.map.addLayer({
            id: project,
            type: 'circle',
            source: project,
            paint: {
              'circle-radius': [
                'interpolate', 
                ['linear'], ['zoom'], 
                1, project === "ports" ? 3 : 1, 
                2.99, project === "ports" ? 6.5 : 1, 
                4, project === "ports" ? 10 : 2, 
              ],              
              'circle-stroke-color': [
                'match',
                ['get', 'Status'],
                'Planned',
                '#78708A',
                'Completed',
                '#78708A ',
                '#2C4C58'
              ],
              'circle-stroke-width': [
                'match',
                ['get', 'Status'],
                'Planned',
                2,
                'Completed',
                1,
                1
              ],
              'circle-color': project === "ports" ? '#78708A' :'#605675',
              'circle-opacity': [
                'match',
                ['get', 'Status'],
                'Planned',
                0,
                'Completed',
                1,
                1
              ]
            },
            layout: {
              'visibility': 'none',
            }
          });           
        }
      });
    },
    async addTraffickingFlow() {
      const data = await d3.csv('data/trafficking.csv');
      const traffickingLayers = d3.groups(data, d => d.category);
      const singleRouteLayers = traffickingLayers.slice(2);
      const routeNarcLayers = d3.groups(traffickingLayers[1][1], d => d.subcat);
      const routeEnvLayers = d3.groups(traffickingLayers[0][1], d => d.subcat);

      let colorConditional = {
        'Wildlife': [0, 47, 19],
        'Gold': [39, 102, 66], 
        'Timber': [101,139,118],
        'Gemstones': [137,188,160],
        'Charcoal': [52,182,133],
        'ODS': [91,239,183],
        'Waste': [189,238,220],
        'Heroin': [227,121,0],
        'Synthetic drugs': [255,164,61],
        'Cocaine': [221,172,116],
        'Precursors': [255,212,169],
        'Counterfeit goods and medicines': [163,51,71],
        'Migrant smuggling and trafficking': [69,140,189],
      };

      routeEnvLayers.forEach(d => {
        let traffickingLayer = new MapboxLayer({
          type: ArcLayer,
          id: `${d[0].split(' ')[0]}-flow`,
          data: d[1],
          getWidth: 2.5,
          pickable: true,
          pickingRadius: 100,
          onHover: (info, event) => this.setTooltipArc(info, event),
          onClick: info => this.setTooltipArcMobile(info),
          getSourcePosition: d => [+d.sourceLong, +d.sourceLat],
          getTargetPosition: d => [+d.destLong, +d.destLat],
          getSourceColor: d => colorConditional[d.subcat],
          getTargetColor: d => colorConditional[d.subcat],
        });
        this.map.addLayer(traffickingLayer); 
        this.map.setLayoutProperty(`${d[0].split(' ')[0]}-flow`, 'visibility', 'none') 
      })

      routeNarcLayers.forEach(d => {
        let traffickingLayer = new MapboxLayer({
          type: ArcLayer,
          id: `${d[0].split(' ')[0]}-flow`,
          data: d[1],
          getWidth: 2.5,
          pickable: true,
          pickingRadius: 100,
          onHover: (info, event) => this.setTooltipArc(info, event),
          onClick: info => this.setTooltipArcMobile(info),
          getSourcePosition: d => [+d.sourceLong, +d.sourceLat],
          getTargetPosition: d => [+d.destLong, +d.destLat],
          getSourceColor: d => colorConditional[d.subcat],
          getTargetColor: d => colorConditional[d.subcat],
        });
        this.map.addLayer(traffickingLayer); 
        this.map.setLayoutProperty(`${d[0].split(' ')[0]}-flow`, 'visibility', 'none') 
      })

      singleRouteLayers.forEach(d => {
        let traffickingLayer = new MapboxLayer({
          type: ArcLayer,
          id: `${d[0]}-flow`,
          data: d[1],
          getWidth: 2.5,
          pickable: true,
          pickingRadius: 100,
          onHover: (info, event) => this.setTooltipArc(info, event),
          onClick: info => this.setTooltipArcMobile(info),
          getSourcePosition: d => [+d.sourceLong, +d.sourceLat],
          getTargetPosition: d => [+d.destLong, +d.destLat],
          getSourceColor: d => colorConditional[d.subcat],
          getTargetColor: d => colorConditional[d.subcat],
        });
        this.map.addLayer(traffickingLayer);
        this.map.setLayoutProperty(`${d[0]}-flow`, 'visibility', 'none') 
      })      
    },
    addTraffickingPoints() {
      // add source
      this.map.addSource('trafficking-points', {
        type: 'geojson',
        data: 'data/trafficking-points.json',
      });

      // add points
      this.map.addLayer({
        id: 'Wildlife-point',
        type: 'circle',
        source: 'trafficking-points',
        paint: {
          'circle-radius': 4.5,
          'circle-color': '#002F13',
          'circle-opacity': 0.9
        },
        layout: {
          visibility: 'none'
        },                 
      });   
    },
    addTraffickingLayers() {
      this.addTraffickingPoints();
      this.addTraffickingFlow();
    },
    addHubLayers() {
      // add source
      this.map.
        addSource('hubs', {
          type: 'geojson',
          data: 'data/hubs.json',
          'cluster': true,
          'clusterRadius': 50,
          'clusterMaxZoom': 7,       
        })
        .addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'hubs',
          paint: {
            'circle-radius': [
              'interpolate', 
              ['linear'], ['zoom'], 
              1, 25, 
              3, 20, 
              5, 20, 
              7, 25, 
              10, 35,  
            ],
            'circle-color': '#E9E2DA',
            'circle-stroke-color': '#47385C',
            'circle-stroke-width': 2,            
            'circle-opacity': 1,
          }
        })
        .addLayer({
          id: 'cluster-label',
          type:'symbol',
          source:'hubs',
          layout:{
            'text-field': "{point_count}",
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-size': 15
          },
          paint: {
            'text-color': '#47385C'
          }
        });

      this.map.addLayer({
        id: 'hub-layer',
        type: 'circle',
        source: 'hubs',
        'filter': ['!=', ['get', 'cluster'], true], // this is important!!!!!
        'paint': {
          'circle-color': '#E9E2DA',
          'circle-stroke-color': '#47385C',
          'circle-stroke-width': 1,
          'circle-radius': [
            'interpolate', 
            ['linear'], ['zoom'], 
            1, 5, 
            3, 13, 
            5, 15, 
            7, 20, 
            10, 30, 
          ],
          'circle-opacity': 0.7         
        }
      });

      this.handleHubData();
    },
    async handleHubData() {
      const data = await d3.json('data/hubs.json');
      this.hubSource = {
        "type":"FeatureCollection",
        "features": []
      };
      this.hubSource.features = data.features;
    },
    handleHubSwitch(items) {
      let source = {
        "type":"FeatureCollection",
        "features": []
      };

      let filteredArray = items;

      if (filteredArray.includes('Human')) {
        filteredArray = filteredArray.filter(d => d !== 'Human');
        filteredArray.push('Human smuggling');
        filteredArray.push('Human trafficking');
      }
      
      source.features = this.hubSource.features.filter(d => {
        let AllCommList = d.properties.AllComm.split(', ');
        return filteredArray.some(i => AllCommList.includes(i));
      }).map(d => d);

      this.map.getSource('hubs').setData(source);
    },        
    handleSwitchSelections(layers, selectedFeature) {
      if (layers === 'People-flow' || layers === 'Others-flow') {
        this.map.setLayoutProperty(layers, 'visibility',  selectedFeature ? 'visible' : 'none');
      } 
      // Belt and Roads
      else if (layers[0].split('-')[0] === 'belt' || layers[0].split('-')[0] === 'corridor') {
        layers.forEach(d => {
          let id = d.split('_')[0];  
          this.map.setLayoutProperty(d, 'visibility',  _kebabCase(selectedFeature).includes(_kebabCase(id)) ? 'visible' : 'none');
      })} 
      else {
        layers.forEach(d => {
          let id = d.split('-')[0];  
          this.map.setLayoutProperty(d, 'visibility',  _kebabCase(selectedFeature).includes(_kebabCase(id)) ? 'visible' : 'none');
      })        
      }
    },
    handleTooltip() {
      const corrsbg = Array.from({length: 6}, (d, i) => 'corridor-' + (i + 1) + '_bg');
      const beltsbg = Array.from({length: 2}, (d, i) => 'belt-' + (i + 1) + '_bg');
      
      const allMapDataLayers = [
        'ports', 'railways-bg',
        ...corrsbg,
        ...beltsbg,
        'hub-layer',
        'Wildlife-point'
      ];

      // iterate through layers to attach mouseevent
      allMapDataLayers.forEach(layer => {
        this.map.on('mousemove', layer, this.setTooltip);
        this.map.on('mouseleave', layer, this.exitTooltip);        
      });

      this.map.on('click', 'hub-layer', this.openSideDrawer);
      this.map.on('click', 'clusters', e => {
        const coord = e.features[0].geometry.coordinates;
        const currentZoom = this.map.getZoom();
        if (currentZoom <= 7.5) {
          this.map.flyTo({ center: [coord[0], coord[1]], zoom: currentZoom + 1.5 });
        }
      });

      const isTablet = window.innerWidth <= 1368;
      // ipad 
      if (isTablet) {
        console.log('ipad', window.orientation)
        this.map.off('mouseenter', 'hub-layer', this.setTooltip);
        this.map.off('mouseleave', 'hub-layer', this.exitTooltip);          
        this.map.on('click', 'hub-layer', this.openSideDrawer);
      }

      // change to click on mobile
      if (this.$vuetify.breakpoint.smAndDown) {
        allMapDataLayers.forEach(layer => {
          this.map.off('mouseenter', layer, this.setTooltip);
          this.map.off('mouseleave', layer, this.setTooltip);          
          this.map.on('click', layer, this.handleInfoDrawer);       
        })
      }
    },
    openSideDrawer(e) {
      // on desktop only
      this.$store.dispatch('drawerState/updateDrawerState', true);
      this.$store.dispatch('drawerState/updateDrawerInfo', e.features[0].properties);
    },    
    handleInfoDrawer(e) {
      // when users click on a feature on the map,
      // 1. the toolbar button for info is selected and is active
      // 2. the drawer opens
      this.$store.dispatch('drawerState/updateSelectedButton', 'info');
      this.$store.dispatch('drawerState/updateInfoDrawerState', true);

      // insert the information into the drawer
      this.$store.dispatch('popupState/updateSelectedFeature', e.features[0].source);
      this.$store.dispatch('popupState/updateProperties', e.features[0].properties);    
    },
    exitTooltip() {
      const popupEl = document.getElementById('mapboxgl-custom-popup');
      popupEl.style.display = 'none';  
    },    
    setTooltip(e) {

      // positioning popup
      const popupEl = d3.select('#mapboxgl-custom-popup');
      const popupElProp = popupEl.node().getBoundingClientRect();
      const boundingContainer = d3.select('#map').node().getBoundingClientRect();

      if (e.point.y > boundingContainer.height - 100) {
        popupEl
          .style('display', 'block')
          .style('transform', `translate(${e.point.x}px, ${e.point.y - popupElProp.height}px)`);            
      } else {
        popupEl
          .style('display', 'block')
          .style('transform', `translate(${e.point.x + 10}px, ${e.point.y}px)`);     
      } 
      // getting the properties for popup
      this.$store.dispatch('popupState/updateSelectedFeature', e.features[0].source);
      this.$store.dispatch('popupState/updateProperties', e.features[0].properties); 
    },
    // special tooltip function for arcs because we are using deck 
    // used with onClick and onHover for mobile and desktop respectively
    setTooltipArc(info, event) {
      // ignores when the info.object is undefined or layer is turned off
      const layerState = this.map.getLayoutProperty(info.layer.id, 'visibility');
      if (!info.object || layerState === 'none') return

      const popupEl = document.getElementById('mapboxgl-custom-popup');
      popupEl.style.display = 'block';    
      popupEl.style.transform = `translate(${event.offsetCenter.x}px, ${event.offsetCenter.y}px)`
      // getting the properties for popup
      this.$store.dispatch('popupState/updateSelectedFeature', info.object.datasource);
      this.$store.dispatch('popupState/updateProperties', info.object);

      setTimeout(function(){
        popupEl.style.display = 'none';
      }, 2000);
    }, 
    setTooltipArcMobile(info) {
      // only carry out function in mobile
      if (!this.$vuetify.breakpoint.smAndDown) return
      
      // ignores when the info.object is undefined or layer is turned off      
      const layerState = this.map.getLayoutProperty(info.layer.id, 'visibility');
      if (!info.object || layerState === 'none') return

      this.$store.dispatch('drawerState/updateSelectedButton', 'info');
      this.$store.dispatch('drawerState/updateInfoDrawerState', true);

      this.$store.dispatch('popupState/updateSelectedFeature', info.object.datasource);
      this.$store.dispatch('popupState/updateProperties', info.object);
    }   
  },
};
</script>

<style lang="scss" scoped>
@import '~mapbox-gl/dist/mapbox-gl.css';

#map {
  width: 100%;
  height: calc(100vh - 75px);
  position: relative;
  
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: calc(var(--vh, 1vh) * 100 - 56px);

    ::v-deep .mapboxgl-ctrl-group {
      margin-top: 80px;
    }
  }    
}

::v-deep .custom-tooltip {
  .mapboxgl-popup-content {
    border-radius: 3px;
    padding: 16px;
    background-color: rgba(233, 226, 218, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 2px solid #6d58ad;  
  }
  .mapboxgl-popup-tip {
    display: none !important;
  }
}
</style>


  