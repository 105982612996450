<template>
<div id="mapboxgl-custom-popup" class="hidden-sm-and-down">
  <FeatureContent />
</div>
</template>

<script>
import FeatureContent from '@/components/controllers/FeatureContent';

export default {
  name: 'Popup',
  components: {
    FeatureContent
  }
};
</script>

<style lang="scss" scoped> 
#mapboxgl-custom-popup {
  border-radius: 3px;
  padding: 16px;
  background-color: #47385C;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;

  display: none;
  max-width: 350px;

  position: absolute;
  z-index: 25;
  top: 0;
  left: 0;
  will-change: transform;
}
</style>
