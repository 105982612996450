<template>
<div :class="{'content-wrapper' : $vuetify.breakpoint.smAndDown}">
  <v-container mt-md-8 class="hidden-sm-and-down">
    <div class="top-tip" @click="resetMap">
      <h3 class="body-3--gi font-weight-bold mb-4">
        <v-icon color="#E9E2DA" class="mr-2">mdi-undo</v-icon>
        Reset map layers
      </h3>
    </div>    
    <hr/>
  </v-container> 

  <!-- Legend -->
  <v-container pa-4 py-md-0 px-md-4>
    <h3 class="body-3--gi font-weight-bold mb-4">Legend</h3>
    <v-row justify="start">
      <v-col cols="4" md="6">
        <v-row>
          <v-col cols="9" class="pb-1">
            <div class="legend__dot-existing mb-1" />
          </v-col>
          <v-col cols="6" class="pt-0">
            <p class="mb-0 body-4--gi">Existing</p>
          </v-col>          
        </v-row>
      </v-col> 

      <v-col cols="4" md="6">
        <v-row>
          <v-col cols="9" class="pb-1">
            <div class="legend__dot-planned mb-1" />
          </v-col>
          <v-col cols="6" class="pt-0">
            <p class="mb-0 body-4--gi">Planned</p>
          </v-col>          
        </v-row>
      </v-col>
    </v-row>  

    <v-row justify="start">
      <v-col cols="4" md="6">
        <v-row>
          <v-col cols="9" class="pb-1">
            <div class="legend__line-existing mb-1" />
          </v-col>
          <v-col cols="6" class="pt-0">
            <p class="mb-0 body-4--gi">Existing</p>
          </v-col>          
        </v-row>
      </v-col> 

      <v-col cols="4" md="6">
        <v-row>
          <v-col cols="9" class="pb-1">
            <div class="legend__line-planned mb-1" />
          </v-col>
          <v-col cols="6" class="pt-0">
            <p class="mb-0 body-4--gi">Planned</p>
          </v-col>          
        </v-row>
      </v-col>  
    </v-row>

    <v-row justify="start">
      <v-col cols="4" md="6" v-for="(icon, i) in iconList" :key="i">
        <v-row>
          <v-col cols="4" class="pr-xl-1">
            <img
              :src="icon.icon === 'pangolin' ? 
              require('@/assets/allcomm-icons/' + `${icon.icon}` + '.png') :
              require('@/assets/allcomm-icons/' + `${icon.icon}` + '.svg')
              "
              class="legend-icons" 
              alt="icons" 
              width="30px" />            
          </v-col>

          <v-col cols="6" class="pl-xl-1">
            <p class="mb-0 body-4--gi" style="display: inline-block">{{ icon.label }}</p>
          </v-col>          
        </v-row>
      </v-col>      
    </v-row>
  </v-container>
 
  <!-- Instruction button -->
  <v-container>
    <hr class="mb-3"/>
    <div class="top-tip" @click="dialogOpenDesktop = true">
      <h3 class="body-3--gi font-weight-bold mb-4">
        <v-icon color="#E9E2DA" class="mr-2">mdi-lightbulb-outline</v-icon>
        Top tips
      </h3>
    </div>    
  </v-container>

  <v-dialog
    v-model="dialogOpenDesktop"
    max-width="475">
      <div v-if="this.$vuetify.breakpoint.mdAndUp"  class="dialog-text--desktop pa-6">
        <h3 class="body-3--gi font-weight-bold mb-5 text-center"><v-icon color="#E9E2DA" class="mr-2">mdi-lightbulb-outline</v-icon>Top tips</h3>              
        <p class="body-4--gi text--light">
          This tool currently represents trafficking routes and infrastructures related to South East Asia, central and East Africa only.
        </p>
        <p class="body-4--gi mb-0 text--light">
          It is advisable to visualize infrastructure projects and BRI crime hotspots simultaneously.
        </p>
        
        <hr class="my-3">

        <p class="body-4--gi mb-0 text--light">
          Hover over the lines and points to learn more. Click on the BRI crime hotspots circles to read more on the specific hubs.
        </p> 

        <v-btn class="dialog-close px-6 py-2 mt-6" elevation="0" :ripple="false" @click="dialogOpenDesktop = false">
          <p class="body-4--gi mb-0 text--light">
            Got it
          </p>
        </v-btn>               
      </div>
      <div v-if="this.$vuetify.breakpoint.smAndDown"  class="dialog-text pa-4">
        <p class="body-2--gi text--light">
          This tool currently represents trafficking routes and infrastructures related to South East Asia, Central and Eastern Africa only.
        </p>
        <p class="body-2--gi mb-0 text--light">
          It is advisable to visualise infrastructure projects and BRI-crime hotspots simultaneously.
        </p>
        
        <hr class="my-3">

        <v-row align="start" justify="space-between">
          <v-col cols="2">
            <v-icon color='#E9E2DA' style="font-size: 2.7rem; display: block">mdi-gesture-tap</v-icon>
          </v-col>
          <v-col cols="10">            
            <p class="body-2--gi text--light" style="display: block">            
              Move the map around to explore the datapoints. <br>Tap anywhere to continue.
            </p>
          </v-col>
        </v-row>
      </div>    
  </v-dialog>       
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Legend',
  data() {
    return {
      dialogOpenDesktop: false,
      iconList: [
        {label: 'Timber', icon: 'timber', color: '#01d193'},
        {label: 'Pangolin', icon: 'pangolin', color: '#01d193' },
        {label: 'Tiger', icon: 'tiger', color: '#01d193'},
        {label: 'Ivory', icon: 'ivory', color: '#01d193'},
        {label: 'Gemstones', icon: 'gemstones', color: '#01d193'},
        {label: 'Minerals', icon: 'minerals', color: '#01d193'},
        {label: 'Rhino horn', icon: 'rhino', color: '#01d193'},
        {label: 'Synthetic drugs', icon: 'synthetic', color: '#e5a65e'},      
        {label: 'Cocaine', icon: 'cocaine', color: '#e5a65e'},
        {label: 'Heroin', icon: 'heroin', color: '#e5a65e'},
        {label: 'Cannabis', icon: 'cannabis', color: '#e5a65e'},
        {label: 'Human', icon: 'human', color: '#458cbd'},
      ]
    }
  },
  computed: {
    ...mapGetters('layerState', ['map']),  
  },  
  methods: {
    resetMap() {
      this.$store.dispatch('layerState/updateRoutePeople', false);
      this.$store.dispatch('layerState/updateRouteGoods', false);
      this.$store.dispatch('layerState/updateAllRouteNarc', false);
      this.$store.dispatch('layerState/updateAllRouteEnv', false);

      this.$store.dispatch('layerState/updateAllCorridor', false);
      this.$store.dispatch('layerState/updateAllBelt', false);
      this.$store.dispatch('layerState/updateAllProject', false);

      this.$store.dispatch('layerState/updateAllCommodities', true);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  overflow-y: auto;
  height: 500px;
}

.legend__line-planned {
  border-top: 4px dashed #47385C;
  background-color: #E3DCD5;
  width: 110%;
  margin-left: -5px;
}

.legend__line-existing {
  width: 100%;
  height: 5px;
  background-color: #E9E2DA;
}

.legend__dot-existing {
  width: 16px;
  height: 16px;
  background-color: #E9E2DA;
  border: 1px solid #E9E2DA;
  border-radius: 50%;
}

.legend__dot-planned {
  width: 17px;
  height: 17px;
  border-radius: 50%;  
  background-color: transparent;
  border: 4px solid #E3DCD5;
}

.dialog-text--desktop {
  background-color: #47385C;;
  border-radius: 4px;
}

.dialog-text {
  background-color: #6D58AD;;
  border-radius: 4px;
}

hr {
  border: 1px solid #E9E2DA;
}

.v-btn.dialog-close {
  background-color: #6D58AD;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
}

.top-tip {
  cursor: pointer;
}
</style>
