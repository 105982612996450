<template>
  <div class="view-mapcontrols fill-height d-flex flex-column px-2">
    <!-- Layers -->
    <Controls/>

    <!-- Legend -->
    <Legend class="mt-auto"/>
  </div>
</template>

<script>
import Legend from '@/components/ui/Legend';
import Controls from '@/components/controllers/LayerSwitchesDesktop';

export default {
  name: 'MapControls',
  components: {
    Legend,
    Controls,
  },
  data() {
    return {

    };
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.view-mapcontrols {
  background: linear-gradient(180deg, #30273E 0%, #47385C 100%);
  color: #E9E2DA !important;
  height: calc(100vh - 75px);
  overflow-y: scroll;

  .v-expansion-panel-header {
    padding: 0;
  }
  .v-expansion-panel-content ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 16px;
  }
}

.view-mapcontrols::-webkit-scrollbar {
  display: none;
}

.view-mapcontrols{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}    

hr {
  border: 1px solid #E9E2DA;
}
</style>
