import { render, staticRenderFns } from "./LayerSwitchesMobile.vue?vue&type=template&id=56ba80a1&scoped=true&"
import script from "./LayerSwitchesMobile.vue?vue&type=script&lang=js&"
export * from "./LayerSwitchesMobile.vue?vue&type=script&lang=js&"
import style0 from "./LayerSwitchesMobile.vue?vue&type=style&index=0&id=56ba80a1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ba80a1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCheckbox,VCol,VContainer,VIcon,VRow,VSwitch})
