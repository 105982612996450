<template>
<div class="content-wrapper">
  <v-container pa-4>
    <h3 class="body-3--gi font-weight-bold mb-2 mt-4">BRI-Crime Hotspots</h3>
    <v-row>
      <v-col cols="6">
        <!-- ENV  -->
        <v-switch 
          hide-details
          inset flat dense 
          :ripple="false"
          v-model="allCommodities"            
          class="my-0">
          <template v-slot:label>
            <span class="legend-color" style="background-color: #B0A894;"/>
            <span class="body-4--gi">Hotspots</span>
          </template>          
        </v-switch>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="6" class="pt-0">
        <p class="body-4--gi pl-4 my-2">Environmental</p>
        <v-checkbox 
          v-for="(item, i) in hotspotEnv" 
          :key="'hotspotEnv' + i" 
          hide-details flat dense
          color="#E9E2DA" 
          :ripple="false"
          :value= item.value
          v-model="selectedCommodities"                
          class="mt-0 pl-3 mb-2">
          <template v-slot:label>
            <span class="body-4--gi">{{ item.label }}</span>
          </template>           
        </v-checkbox>         
      </v-col>
   
      <v-col cols="6"  class="pt-0">
        <!--  Narcs  -->
        <p class="body-4--gi pl-4 my-2">Narcotics</p>
        <v-checkbox 
          v-for="(item, i) in hotspotNarc" 
          :key="'hotspotNarc' + i" 
          hide-details flat dense
          color="#E9E2DA" 
          :ripple="false"
          :value= item.value
          v-model="selectedCommodities"                
          class="mt-0 pl-3 mb-2">
          <template v-slot:label>
            <span class="body-4--gi">{{ item.label }}</span>
          </template>           
        </v-checkbox>

        <!-- People -->
        <v-checkbox 
          hide-details flat dense
          color="#E9E2DA" 
          :ripple="false"
          class="mt-6 pl-3"
          v-model="selectedCommodities"  
          value= "Human">
          <template v-slot:label>
            <span class="body-4--gi mb-0">People</span>
          </template>           
        </v-checkbox>                             
      </v-col>   
    </v-row> 

    <h3 class="body-3--gi font-weight-bold mb-2 mt-4">Trafficking routes</h3>
    <v-row>
      <v-col cols="6">
        <!-- ENV  -->
        <v-switch 
          hide-details
          inset flat dense 
          :ripple="false"
          v-model="allRouteEnv"
          class="my-0 pl-1">
          <template v-slot:label>
            <span class="body-4--gi">Environmental</span>
          </template>          
        </v-switch>     

        <v-switch 
          v-for="(item, i) in routeEnv" 
          :key="'routeEnv' + i" 
          hide-details 
          :value= item.value
          :ripple="false"
          inset flat dense
          v-model="selectedRouteEnv"                
          class="mt-0 pl-5">
          <template v-slot:label>
            <span class="legend-color" :style="`background-color: ${item.color};`"/>
            <span class="body-4--gi">{{ item.label }}</span>
          </template>           
        </v-switch>           
      </v-col>
   
      <v-col cols="6">
        <!--  Narcs  -->
        <v-switch 
          hide-details
          inset flat dense 
          :ripple="false"
          v-model="allRouteNarc"
          class="my-0 pl-1">
          <template v-slot:label>
            <span class="body-4--gi">Narcotics</span>
          </template>          
        </v-switch>   

        <v-switch 
          v-for="(item, i) in routeNarc" 
          :key="'routeNarc' + i" 
          hide-details 
          :value= item.value
          :ripple="false"
          inset flat dense
          v-model="selectedRouteNarc"                
          class="mt-0 pl-5">
          <template v-slot:label>
            <span class="legend-color" :style="`background-color: ${item.color};`"/>
            <span class="body-4--gi">{{ item.label }}</span>
          </template>           
        </v-switch> 

        <!-- People -->
        <v-switch 
          hide-details
          inset flat dense 
          :ripple="false"
          v-model="routePeople"
          class="mb-0 pl-1 mt-4">
          <template v-slot:label>
            <span class="legend-color" style="background-color: #458CBD;"/>
            <span class="body-4--gi">People</span>
          </template>          
        </v-switch>
        
        <!-- Goods -->
        <v-switch 
          hide-details
          inset flat dense 
          :ripple="false"
          v-model="routeGoods"
          class="mb-0 pl-1 mt-4">
          <template v-slot:label>
            <span class="legend-color" style="background-color: #A33347;"/>
            <span class="body-4--gi">Counterfeit goods and medicine</span>
          </template>          
        </v-switch>                           
      </v-col>   
    </v-row>   

    <h3 class="body-3--gi font-weight-bold mb-2">Infrastructure</h3>
    <v-row>
      <v-col cols="6">
        <!-- Belt and Roads   -->
        <v-switch 
          hide-details
          inset flat dense 
          :ripple="false"
          v-model="allBelt"
          class="my-0 pl-1" color="red">
          <template v-slot:label>
            <span class="legend-color" style="background-color: #6D58AD;"/>
            <span class="body-4--gi">Belt and Roads</span>
          </template>          
        </v-switch>  

        <v-switch 
          v-for="(item, j) in belts" 
          :key="'belt-' + j" hide-details color="#ff2424" 
          :value= item.value 
          :ripple="false"
          v-model="selectedBelt"          
          inset flat dense 
          class="mt-0 pl-5">
            <template v-slot:label>
              <span class="body-4--gi">{{ item.label }}</span>
            </template> 
        </v-switch>

        <!-- Infra projects  -->
        <v-switch 
          hide-details
          inset flat dense
          :ripple="false"
          v-model="allProjects"
          class="mb-0 pl-1 mt-4">
          <template v-slot:label>
            <span class="legend-color" style="background-color: #78708A;"/>
            <span class="body-4--gi">Infrastructure projects</span>
          </template>          
        </v-switch>  

        <v-switch 
          v-for="(item, i) in projects" 
          :key="'project' + i" hide-details
          :ripple="false"
          :value= item.value
          v-model="selectedProjects"
          @change="$emit('update:selected-projects', $event)"          
          inset flat dense
          class="mt-0 pl-5">
          <template v-slot:label>
            <!-- <span class="legend-color" :style="`background-color: ${item.color};`"/> -->
            <span class="body-4--gi">{{ item.label }}</span>
          </template>           
        </v-switch>        
      </v-col>
   
      <v-col cols="6">
        <v-switch 
          hide-details
          inset flat dense 
          :ripple="false"
          v-model="allCorridor"
          class="my-0 pl-1">
          <template v-slot:label>
            <span class="legend-color" style="background-color: #47385C;"/>
            <span class="body-4--gi">Economic corridors</span>
          </template>          
        </v-switch>

        <v-switch 
          v-for="(item, j) in corridors" 
          :key="'corridor' + j" hide-details color="#ff2424" 
          :value= item.value 
          :ripple="false"
          v-model="selectedCorridor"          
          inset flat dense 
          class="mt-0 pl-5">
            <template v-slot:label>
              <span class="body-4--gi">{{ item.label }}</span>
            </template> 
        </v-switch>                   
      </v-col>  
    </v-row>         
  </v-container>

  <v-container pa-4 pb-8>
    <hr class="my-3"/>
    <div class="top-tip" @click="resetMap">
      <h3 class="body-3--gi font-weight-bold mb-4">
        <v-icon color="#E9E2DA" class="mr-2">mdi-undo</v-icon>
        Reset map layers
      </h3>
    </div>    
  </v-container>  
</div>
</template>

<script>
export default {
  name: 'LayerSwitchMobile', 
  data() {
    return {
      // Infrastructure
      projects: [
        {label:'Ports', value: 'ports', color: '#78708A'},        
        {label:'Railways', value: 'railways', color: '#605675'},
      ],
      belts: [
        {label:'Maritime Silk Road', value: 'belt-1'},
        {label:'Silk Road Economic Belt', value: 'belt-2'},
      ],
      corridors: [
        {label:'Bangladesh-China-India-Myanmar', value: 'corridor-1'},
        {label:'China-Central Asia-West Asia', value: 'corridor-2'},
        {label:'China-Indochina Peninsula', value: 'corridor-3'},
        {label:'China-Mongolia-Russia', value: 'corridor-4'},
        {label:'China-Pakistan', value: 'corridor-5'},
        {label:'New Eurasia Land Bridge', value: 'corridor-6'},
      ],  

      // Hotspotsspots
      hotspotEnv: [
        {label: 'Ivory', value: 'Ivory', color: '#BDEEDC'},
        {label: 'Pangolin', value: 'Pangolin or pangolin scales', color: '#BDEEDC'},
        {label: 'Rhino horns', value: 'Rhino horn', color: '#BDEEDC'},
        {label: 'Tiger', value: 'Tiger', color: '#BDEEDC'},
        {label: 'Timber', value: 'Timber', color: '#BDEEDC'},
        {label: 'Minerals', value: 'Minerals', color: '#789C88'},
        {label: 'Gemstones', value: 'Gemstones', color: '#34B685'},
      ],
      hotspotNarc: [
        {label: 'Cannabis', value: 'Cannabis', color: '#BDEEDC'},
        {label: 'Cocaine', value: 'Cocaine', color: '#BDEEDC'},
        {label: 'Heroin', value: 'Heroin', color: '#BDEEDC'},
        {label: 'Synthetic drugs', value: 'Synthetic drugs', color: '#BDEEDC'},
      ],      

      // Routes
      routeEnv: [
        {label: 'Wildlife', value: 'Wildlife-flow', color: '#002F13'},
        {label: 'Gold', value: 'Gold-flow', color: '#276642'},
        {label: 'Timber', value: 'Timber-flow', color: '#658B76'},
        {label: 'Gemstones', value: 'Gemstones-flow', color: '#89BCA0'},
        {label: 'Charcoal', value: 'Charcoal-flow', color: '#34B685'},
        {label: 'ODS', value: 'ODS-flow', color: '#5BEFB7' },
        {label: 'E-waste', value: 'Waste-flow', color: '#BDEEDC'},
      ],      
      routeNarc: [
        {label: 'Heroin', value: 'Heroin-flow', color: '#E37900'},
        {label: 'Synthetic drugs', value: 'Synthetic-flow', color: '#FFA43D'},
        {label: 'Cocaine', value: 'Cocaine-flow', color: '#DDAC74'},
        {label: 'Precursors', value: 'Precursors-flow', color: '#FFD4A9'},
      ],             
    };
  },
  computed: {
    // Belts
    allBelt: {
      get: function() {
        return this.$store.getters['layerState/allBelt'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllBelt', value);
      }  
    },
    selectedBelt: {
      get: function () {
        return this.$store.getters['layerState/belt'];
      },      
      /* We add a setter */
      set: function (value) {
        this.$store.dispatch('layerState/updateBelt', value);
      }
    },

    // Corridors
    allCorridor: {
      get: function() {
        return this.$store.getters['layerState/allCorridor'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllCorridor', value);
      }  
    },
    selectedCorridor: {
      get: function () {
        return this.$store.getters['layerState/corridor'];
      },      
      /* We add a setter */
      set: function (value) {
        this.$store.dispatch('layerState/updateCorridor', value);
      }
    },

    // Infrastructure Projects   
    allProjects: {
      get: function() {
        return this.$store.getters['layerState/allProject'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllProject', value);
      }      
    },
    selectedProjects: {
      get: function () {
        return this.$store.getters['layerState/project'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateProject', value);
      }
    },

    // Routes -Env 
    allRouteEnv: {
      get: function() {
        return this.$store.getters['layerState/allRouteEnv'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllRouteEnv', value);
      }      
    },
    selectedRouteEnv: {
      get: function () {
        return this.$store.getters['layerState/routeEnv'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateRouteEnv', value);
      }
    },
    
    // Routes - Narc
    allRouteNarc: {
      get: function() {
        return this.$store.getters['layerState/allRouteNarc'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllRouteNarc', value);
      }      
    },
    selectedRouteNarc: {
      get: function () {
        return this.$store.getters['layerState/routeNarc'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateRouteNarc', value);
      }
    },

    // Routes - People
    routePeople: {
      get: function () {
        return this.$store.getters['layerState/routePeople'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateRoutePeople', value);
      }
    },

    // Routes - Goods
    routeGoods: {
      get: function () {
        return this.$store.getters['layerState/routeGoods'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateRouteGoods', value);
      }
    },

    // Hotspotsspots
    allCommodities: {
      get: function() {
        return this.$store.getters['layerState/allCommodities'];
      },
      set: function(value) {
        return this.$store.dispatch('layerState/updateAllCommodities', value);
      }   
    },
    
    selectedCommodities: {
      get: function () {
        return this.$store.getters['layerState/commodities'];
      },      
      set: function (value) {
        this.$store.dispatch('layerState/updateCommodities', value);
      }
    },       
  },
  methods: {
    resetMap() {
      this.$store.dispatch('layerState/updateRoutePeople', false);
      this.$store.dispatch('layerState/updateRouteGoods', false);
      this.$store.dispatch('layerState/updateAllRouteNarc', false);
      this.$store.dispatch('layerState/updateAllRouteEnv', false);

      this.$store.dispatch('layerState/updateAllCorridor', false);
      this.$store.dispatch('layerState/updateAllBelt', false);
      this.$store.dispatch('layerState/updateAllProject', false);

      this.$store.dispatch('layerState/updateAllCommodities', true);
    }    
  }
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  overflow-y: auto;
  height: 500px;
  // padding-bottom: 300px !important;
}

.v-input--selection-controls {
   padding-top: 0px !important;
}

::v-deep .v-input--selection-controls__input {
  margin-top: 10px !important;  
  .v-input--switch__track {
    opacity: 1;
    border: 1px solid #e3dcd5;
    background-color: #47385c;
  }
  .v-input--switch__thumb {
    background-color: #e3dcd5;
    top: calc(50% - 9px);    
  }
}

.v-input--is-label-active {
  ::v-deep .v-input--selection-controls__input {
    .v-input--switch__track {
      background-color: #e3dcd5;
    }
    .v-input--switch__thumb {
      background-color: #47385c;
    }
  }
}

.v-input--checkbox {
  ::v-deep .v-input--selection-controls__input {
    margin-top: 0px !important;  
  }

  ::v-deep .mdi-checkbox-blank-outline {
    color: #E9E2DA !important;
  }
}

.legend-color {
  width: 12px; 
  height: 12px; 
  margin-right: 6px;
  display: inline-block; 
  border: 0.5px solid #E9E2DA; 
  vertical-align: middle; 
}

hr {
  border: 1px solid #E9E2DA;
}

</style>
